import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useServerData } from '@xingternal/hops';

import gdprGerman from './gdpr_de.svg';
import gdprEnglish from './gdpr_en.svg';

import styles from './GdprImage.css';

const GdprImage = () => {
  const { language } = useServerData();
  const imgSrc = language === 'de' ? gdprGerman : gdprEnglish;

  return (
    <div className={styles.container}>
      <FormattedMessage id="WELCOME_GDPR_IMG_ALT">
        {alt => (
          <img
            src={imgSrc}
            className={styles.img}
            alt={alt}
            loading="lazy"
            title={alt}
          />
        )}
      </FormattedMessage>
    </div>
  );
};

export default GdprImage;
