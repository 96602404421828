import { defineMessages } from 'react-intl';

export default defineMessages({
  headline: {
    id: 'WELCOME_INVITATION_HEADLINE',
    defaultMessage: '{firstname} {lastname} lädt Dich ein.',
  },
  subline: {
    id: 'WELCOME_INVITATION_SUBLINE',
    defaultMessage:
      'Registrier Dich hier, um {firstname} als XING Kontakt zu bestätigen.',
  },
});
