import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Hero, Headline, BodyCopy } from '@xingternal/typography';
import { GridContainer, Row, Column } from '@xingternal/xing-grid';

import messages from './messages';
import styles from './UspSection.css';

const UspSection = () => (
  <div className={styles.wrapper}>
    <GridContainer type="wide">
      <Row>
        <Column size={12}>
          <Hero size="medium">
            <FormattedMessage {...messages.uspTitle} />
          </Hero>
        </Column>
      </Row>
      <Row>
        {[1, 2, 3, 4].map(x => (
          <Column key={x} size={12} sizeTablet={6} sizeDesktop={3}>
            <Headline size="large">
              <FormattedMessage {...messages[`usp${x}Title`]} />
            </Headline>
            <BodyCopy>
              <FormattedMessage {...messages[`usp${x}Copy`]} />
            </BodyCopy>
          </Column>
        ))}
      </Row>
    </GridContainer>
  </div>
);

export default UspSection;
