import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

import { useConfig } from '@xingternal/hops';

import messages from './messages';

const shortcutIco =
  'https://www.xing.com/assets/frontend_minified/img/shared/xing_r1.ico';
const appleIco =
  'https://www.xing.com/assets/frontend_minified/img/shared/xing_icon_apple.png';

const HtmlHead = ({ pageName = 'startPage', children }) => {
  const { externalUrl, staticAssetUrl } = useConfig();
  const { formatMessage } = useIntl();

  const linkTags = [
    { rel: 'shortcut icon', href: shortcutIco, type: 'image/ico' },
    {
      rel: 'apple-touch-icon',
      href: appleIco,
    },
    {
      rel: 'search',
      type: 'application/opensearchdescription+xml',
      title: 'XING',
      href: '/media/xing-opensearch.xml',
    },
  ];

  const metaTags = [
    {
      name: 'description',
      content: formatMessage(messages[`${pageName}Description`]),
    },
    {
      name: 'HandHeldFriendly',
      content: 'true',
    },
    {
      name: 'apple-mobile-web-app-capable',
      content: 'yes',
    },
    {
      name: 'apple-mobile-web-app-status-bar-style',
      content: 'black',
    },
    { name: 'p:domain_verify', content: '67d537533de2cff8410b2b6048c8de0d' },
  ];

  const ogTags = [
    {
      property: 'og:title',
      content: formatMessage(messages[`${pageName}Title`]),
    },
    {
      property: 'og:site_name',
      content: formatMessage(messages[`${pageName}SiteName`]),
    },
    {
      property: 'og:url',
      content: externalUrl,
    },
    {
      property: 'og:image',
      content: `${staticAssetUrl}/og-cover-image.png`,
    },
    {
      property: 'og:description',
      content: formatMessage(messages[`${pageName}Description`]),
    },
    {
      property: 'og:type',
      content: 'website',
    },
  ];

  return (
    <Helmet
      title={formatMessage(messages[`${pageName}Title`])}
      meta={[...metaTags, ...ogTags]}
    >
      {linkTags.map(linkTag => (
        <link key={linkTag.href} {...linkTag} />
      ))}
      {children}
    </Helmet>
  );
};

export default HtmlHead;
