import React from 'react';

import { useConfig, useServerData } from '@xingternal/hops';

import RegistrationForm from '@xingternal/account-packages/registration';

import trackAction from '../Components/PageviewTracker/trackAction';

export default props => {
  const { externalUrl, googleSiteKey } = useConfig();
  const { language } = useServerData();

  const formProps = {
    externalUrl,
    googleSiteKey,
    language,
    emailOnly: false,
  };

  return (
    <RegistrationForm
      {...formProps}
      {...props}
      onSubmitValidateErrors={errors => {
        errors.forEach(({ error }) => {
          let trackKey = `welcome_signup_error_UNKNOWN`;

          if (typeof error === 'string') {
            trackKey = `welcome_signup_error_${error}`;
          } else if (typeof error === 'object') {
            trackKey = `welcome_signup_error_${error.id || 'UNKNOWN'}`;
          }

          trackAction(trackKey, props.trackingContext);
        });
      }}
    />
  );
};
