import styled from 'styled-components';

import { mediaConfined } from '@xingternal/layout-tokens';
import {
  xdlColorText,
  spaceL,
  spaceS,
  scale120,
  scale160,
} from '@xingternal/tokens';

import {
  sectionInnerContainerMixin,
  sectionMixin,
} from '../../Pages/Start/variables.style';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: ${spaceL} 0;
  margin-bottom: ${scale160};

  ${sectionMixin}
  ${sectionInnerContainerMixin}
`;

export const NavigationContainer = styled.div`
  margin-bottom: ${scale120};
`;

export const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;

  @media ${mediaConfined} {
    justify-content: space-between;
  }
`;

export const ListItem = styled.li`
  padding-right: ${spaceL};
  padding-bottom: ${spaceS};
`;

export const Link = styled.a`
  color: ${xdlColorText};
  line-height: 20px;
  font-size: 14px;
  font-weight: 700;
`;
