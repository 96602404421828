import React from 'react';
import { FormattedMessage } from 'react-intl';

import ProfileInfo from '@xingternal/profile-info';

import styles from './Invitation.css';
import messages from './messages';

const Inviter = ({ inviter }) => {
  // Exit if there is no inviter or all fields are empty
  if (!inviter || !Object.values(inviter).some(value => value)) return null;

  const {
    first_name: firstName,
    last_name: lastName,
    photo_url: photoUrl,
  } = inviter;

  return (
    <div className={styles.marginBottom} data-qa="inviter">
      <ProfileInfo
        vertical={false}
        profileImage={{
          size: 'large',
          src: photoUrl && `${photoUrl}.128x128.jpg`,
          profileName: `${firstName} ${lastName}`,
        }}
        headline={{
          xlarge: true,
          children: (
            <FormattedMessage
              {...messages.headline}
              values={{ firstname: firstName, lastname: lastName }}
            />
          ),
        }}
        textBody={[
          {
            medium: true,
            children: (
              <FormattedMessage
                {...messages.subline}
                values={{ firstname: firstName }}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default Inviter;
