import { ttt, matchEnvByHost } from '@xingternal/malt-tracking';

const initializedTtt = ttt;
const env = matchEnvByHost({
  localhost: 'development',
  'login.xing.com': 'production',
  'www.xing.com': 'production',
  default: 'preview',
});

initializedTtt.init(env, { fetchTrackingData: true });

if (typeof window !== 'undefined') window.ttt = initializedTtt;

export default initializedTtt;
