import { defineMessages } from 'react-intl';

export default defineMessages({
  startPageTitle: {
    id: 'WELCOME_STARTPAGE_META_TITLE_JOB_SEARCH',
    defaultMessage: 'XING – For a better working life',
  },
  startPageDescription: {
    id: 'WELCOME_STARTPAGE_META_DESCRIPTION_JOB_SEARCH',
    defaultMessage:
      'XING is a social network for business and career. Find old acquaintances, new people, jobs, events, companies, and groups.',
  },
  startPageSiteName: {
    id: 'WELCOME_STARTPAGE_OG_SITENAME',
    defaultMessage: 'XING',
  },
  signupPageTitle: {
    id: 'WELCOME_SIGNUP_PAGE_META_TITLE',
    defaultMessage: 'Registrieren Sie sich jetzt | XING',
  },
  signupPageDescription: {
    id: 'WELCOME_STARTPAGE_META_DESCRIPTION',
    defaultMessage:
      'XING is a social network for business and career. Find old acquaintances, new people, jobs, events, companies, and groups.',
  },
  signupPageSiteName: {
    id: 'WELCOME_STARTPAGE_OG_SITENAME',
    defaultMessage: 'XING',
  },
  languagePageTitle: {
    id: 'WELCOME_LANGUAGE_PAGE_META_TITLE',
    defaultMessage: 'Sprache auwählen | XING',
  },
  languagePageDescription: {
    id: 'WELCOME_STARTPAGE_META_DESCRIPTION',
    defaultMessage:
      'XING is a social network for business and career. Find old acquaintances, new people, jobs, events, companies, and groups.',
  },
  languagePageSiteName: {
    id: 'WELCOME_STARTPAGE_OG_SITENAME',
    defaultMessage: 'XING',
  },
  confirmationPageTitle: {
    id: 'WELCOME_CONFIRMATION_PAGE_META_TITLE',
    defaultMessage: 'Aktivieren Sie ihren account | XING',
  },
  confirmationPageDescription: {
    id: 'WELCOME_STARTPAGE_META_DESCRIPTION',
    defaultMessage:
      'XING is a social network for business and career. Find old acquaintances, new people, jobs, events, companies, and groups.',
  },
  confirmationPageSiteName: {
    id: 'WELCOME_STARTPAGE_OG_SITENAME',
    defaultMessage: 'XING',
  },
  completionPageTitle: {
    id: 'WELCOME_COMPLETION_PAGE_META_TITLE',
    defaultMessage: 'Willkommen bei XING! | XING',
  },
  completionPageDescription: {
    id: 'WELCOME_STARTPAGE_META_DESCRIPTION',
    defaultMessage:
      'XING is a social network for business and career. Find old acquaintances, new people, jobs, events, companies, and groups.',
  },
  completionPageSiteName: {
    id: 'WELCOME_STARTPAGE_OG_SITENAME',
    defaultMessage: 'XING',
  },
  invitationPageTitle: {
    id: 'WELCOME_COMPLETION_PAGE_META_TITLE',
    defaultMessage: 'Willkommen bei XING! | XING',
  },
  invitationPageDescription: {
    id: 'WELCOME_STARTPAGE_META_DESCRIPTION',
    defaultMessage:
      'XING is a social network for business and career. Find old acquaintances, new people, jobs, events, companies, and groups.',
  },
  invitationPageSiteName: {
    id: 'WELCOME_STARTPAGE_OG_SITENAME',
    defaultMessage: 'XING',
  },
});
