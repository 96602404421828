import { defineMessages } from 'react-intl';

export default defineMessages({
  uspTitle: {
    id: 'WELCOME_SIGNUP_USPS_TITLE',
    defaultMessage: 'XING – all you need for a successful career.',
  },
  usp1Title: {
    id: 'WELCOME_SIGNUP_USP_PRESENCE_TITLE',
    defaultMessage: 'Greater presence',
  },
  usp1Copy: {
    id: 'WELCOME_SIGNUP_USP_PRESENCE_COPY',
    defaultMessage:
      'Join the size="large"st online business network in German-speaking countries.',
  },
  usp2Title: {
    id: 'WELCOME_SIGNUP_USP_CONTACTS_TITLE',
    defaultMessage: 'Valuable contacts',
  },
  usp2Copy: {
    id: 'WELCOME_SIGNUP_USP_CONTACTS_COPY',
    defaultMessage: 'Connect with over 17 million members.',
  },
  usp3Title: {
    id: 'WELCOME_SIGNUP_USP_SECURITY_TITLE',
    defaultMessage: 'Maximum secuirty',
  },
  usp3Copy: {
    id: 'WELCOME_SIGNUP_USP_SECURITY_COPY',
    defaultMessage: 'XING complies with German data protection law.',
  },
  usp4Title: {
    id: 'WELCOME_SIGNUP_USP_FREE_TITLE',
    defaultMessage: 'Free registration',
  },
  usp4Copy: {
    id: 'WELCOME_SIGNUP_USP_FREE_COPY',
    defaultMessage: 'Basic XING membership is free and always will be.',
  },
});
