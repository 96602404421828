import React from 'react';

import { GridContainer, Row, Column } from '@xingternal/xing-grid';
import { Hero } from '@xingternal/typography';

import {
  Wrapper,
  WrapperWithSpace,
  HeadlineWrapper,
  ImageContainer,
  ContentRow,
  ResponsiveImage,
  ResponsiveEyeCatcher,
} from './IllustrationGrid.styled';

const IllustrationGrid = ({
  headline = null,
  illustration,
  children,
  eyeCatcher = null,
}) => {
  const WrapperComponent = headline ? Wrapper : WrapperWithSpace;

  return (
    <WrapperComponent>
      <GridContainer type="wide">
        <Row>
          <Column size={12} sizeWide={5}>
            {headline && (
              <Row>
                <Column size={12}>
                  <HeadlineWrapper>
                    <Hero size="small" sizeWide="medium">
                      {headline}
                    </Hero>
                  </HeadlineWrapper>
                </Column>
              </Row>
            )}
            <ContentRow>
              <Column size={12}>{children}</Column>
            </ContentRow>
          </Column>
          <ImageContainer size={12} sizeWide={6} offsetWide={1}>
            {illustration && (
              <React.Fragment>
                {eyeCatcher && <ResponsiveEyeCatcher src={eyeCatcher} />}
                <ResponsiveImage src={illustration} />
              </React.Fragment>
            )}
          </ImageContainer>
        </Row>
      </GridContainer>
    </WrapperComponent>
  );
};

export default IllustrationGrid;
