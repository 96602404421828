import styled from 'styled-components';

import { mediaWide, mediaConfined } from '@xingternal/layout-tokens';
import { scale130, scale190 } from '@xingternal/tokens';
import { Column, Row } from '@xingternal/xing-grid';

export const Wrapper = styled.div`
  margin: 0 auto 60px;
`;

export const WrapperWithSpace = styled.div`
  margin: ${scale130} auto 60px;

  @media ${mediaConfined} {
    margin-top: ${scale190};
  }
`;

export const HeadlineWrapper = styled.div`
  display: none;
  margin: 60px 0 48px;

  @media ${mediaWide} {
    display: block;
  }
`;

export const ContentRow = styled(Row)`
  display: flex;
  flex-flow: column nowrap;
`;

export const ImageContainer = styled(Column)`
  @media ${mediaWide} {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
`;

export const ResponsiveImage = styled.img`
  width: 100%;
  display: block;
  margin: 0 auto;

  @media ${mediaConfined} {
    width: 70%;
  }

  @media ${mediaWide} {
    width: 100%;
  }
`;

export const ResponsiveEyeCatcher = styled.img`
  max-width: 140px;
  position: absolute;
  top: 0;
  right: 0;

  @media (min-width: 1025px) {
    top: -50px;
    right: 50px;
  }
`;
