import { useEffect, useState } from 'react';

import fetchJson from '@xingternal/malt-network/fetch-json';
import { useConfig } from '@xingternal/hops';

/**
 * Custom hook to fetch data once on first mount
 */
const useFetchInitialState = dataEndpoint => {
  const [response, setResponse] = useState({});
  const [responseError, setResponseError] = useState(null);
  const { jsonHeaders } = useConfig();

  useEffect(() => {
    const hydrateState = async () => {
      try {
        const data = await fetchJson(dataEndpoint, {
          method: 'GET',
          headers: jsonHeaders,
        });

        setResponse(data);
      } catch (error) {
        setResponseError(error);
      }
    };

    hydrateState();
  }, []);

  return [response, responseError];
};

export default useFetchInitialState;
