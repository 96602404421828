import React from 'react';

import { useConfig } from '@xingternal/hops';

import DirectoryNavigation from './DirectoryNavigation';

import { Container } from './DirectoryNavigation.style';

const DirectoryNavigations = () => {
  const { externalUrl } = useConfig();

  return (
    <Container>
      <DirectoryNavigation
        directoryUrl={`${externalUrl}/people`}
        titleTranslationKey="WELCOME_STARTPAGE_MEMBER_DIRECTORY_NAVIGATION_TITLE"
      />
      <DirectoryNavigation
        directoryUrl={`${externalUrl}/jobs/directory`}
        titleTranslationKey="WELCOME_STARTPAGE_JOBS_DIRECTORY_NAVIGATION_TITLE"
      />
    </Container>
  );
};

export default DirectoryNavigations;
