import React from 'react';

import { useConfig } from '@xingternal/hops';

import { GridContainer, Row, Column } from '@xingternal/xing-grid';

import styles from './MemberDirectory.css';

const memberDirectoryRange = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

const MemberDirectory = () => {
  const { externalUrl } = useConfig();

  return (
    <div className={styles.wrapper}>
      <GridContainer type="wide">
        <Row>
          <Column size={12}>
            <div className={styles.container}>
              <h4 className={styles.title}>XING member directory</h4>
              <ul className={styles.list}>
                {memberDirectoryRange.map(character => (
                  <li key={character} className={styles.item}>
                    <a
                      className={styles.link}
                      href={`${externalUrl}/people/${character}/`}
                    >
                      {character.toUpperCase()}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </Column>
        </Row>
      </GridContainer>
    </div>
  );
};

export default MemberDirectory;
