import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ttt from './initializedTtt';

export const PROP_CHANNEL = 'wbm/Welcome';

const PageviewTracker = ({
  base = {},
  context = {},
  pageName = '',
  callback = () => {},
}) => {
  const location = useLocation();

  useEffect(() => {
    ttt.pageview(PROP_CHANNEL, pageName, base, context).finally(() => {
      callback && callback();
    });
  }, [location]);

  return null;
};

export default PageviewTracker;
