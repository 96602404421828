import React from 'react';

import { useConfig } from '@xingternal/hops';
import { HeaderLoggedOut } from '@xingternal/platform-layout-header-logged-out';
import { Footer } from '@xingternal/platform-layout-footer';

import DirectoryNavigations from '../Components/DirectoryNavigations/DirectoryNavigations';
import MemberDirectory from '../Components/MemberDirectory/MemberDirectory';

const CustomFrame = ({
  hideLoginButton,
  hideSignupButton,
  homeLink,
  loginLink,
  signupLink,
  withDirectoryNavigations,
  children,
}) => {
  const { externalLoginUrl } = useConfig();
  loginLink = loginLink || externalLoginUrl;

  return (
    <React.Fragment>
      <HeaderLoggedOut
        {...{
          hideLoginButton,
          hideSignupButton,
          homeLink,
          loginLink,
          signupLink,
        }}
      />
      {children}
      {withDirectoryNavigations ? (
        <>
          <DirectoryNavigations />
          <Footer {...{ homeLink }} />
        </>
      ) : (
        <>
          <Footer {...{ homeLink }} />
          <MemberDirectory />
        </>
      )}
    </React.Fragment>
  );
};

export default CustomFrame;
