import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Headline } from '@xingternal/typography';

import {
  Link,
  List,
  ListItem,
  NavigationContainer,
} from './DirectoryNavigation.style';

const memberDirectoryRange = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

const DirectoryNavigation = ({ titleTranslationKey, directoryUrl }) => {
  return (
    <NavigationContainer>
      <Headline size="large" forwardedAs="h3">
        <FormattedMessage id={titleTranslationKey} />
      </Headline>
      <List>
        {memberDirectoryRange.map(character => (
          <ListItem key={character}>
            <Link href={`${directoryUrl}/${character}/`}>
              {character.toUpperCase()}
            </Link>
          </ListItem>
        ))}
      </List>
    </NavigationContainer>
  );
};

export default DirectoryNavigation;
