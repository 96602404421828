import { css } from 'styled-components';
import { scale100, scale160, scale240 } from '@xingternal/tokens';
import { mediaConfined } from '@xingternal/layout-tokens';

export const CONTAINER_MOBILE_MAX_WIDTH = '415px';
export const CONTAINER_MAX_WIDTH = '1024px';
export const CONTAINER_WIDE_MAX_WIDTH = '1260px';
export const CONTAINER_PADDING = scale100;

const sectionInnerContainerMobileCss = css`
  max-width: ${CONTAINER_MOBILE_MAX_WIDTH};
  margin-left: auto;
  margin-right: auto;
`;

export const sectionInnerContainerMixin = css`
  ${sectionInnerContainerMobileCss};

  @media ${mediaConfined} {
    max-width: ${CONTAINER_MAX_WIDTH};
  }
`;

export const sectionWideInnerContainerMixin = css`
  ${sectionInnerContainerMobileCss};

  @media ${mediaConfined} {
    max-width: ${CONTAINER_WIDE_MAX_WIDTH};
  }
`;

export const sectionMixin = css`
  margin-top: ${scale160};
  padding-left: ${CONTAINER_PADDING};
  padding-right: ${CONTAINER_PADDING};
  margin-left: auto;
  margin-right: auto;

  & img {
    max-width: 100%;
  }

  @media ${mediaConfined} {
    margin-top: ${scale240};
  }
`;
