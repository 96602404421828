import React from 'react';

import CustomFrame from './../../Layouts/CustomFrame';
import IllustrationGrid from './../../Layouts/IllustrationGrid';

import HtmlHead from './../../Components/HtmlHead/HtmlHead';
import UspSection from './../../Components/UspSection/UspSection';
import GdprImage from './../../Components/Illustrations/GdprImage';
import PageviewTracker from './../../Components/PageviewTracker/PageviewTracker';
import RegistrationForm from './../../Forms/RegistrationForm';
import useFetchInitialState from './../../hooks/useFetchInitialState';

import Inviter from './Inviter';

import illustration from './illustration.svg';

const Invitation = () => {
  const [{ inviter, invitee }, _error] = useFetchInitialState(
    `/welcome/api/signup/invitation`
  );

  const registrationFromProps = invitee
    ? {
        key: 'invitee',
        prefilledValues: { email: invitee.active_email || '' },
      }
    : { key: 'standard' };

  return (
    <>
      <PageviewTracker pageName={'wbm/Welcome/signup/invitation'} />
      <HtmlHead pageName={'invitationPage'} />
      <CustomFrame hideLoginButton hideSignupButton>
        <IllustrationGrid illustration={illustration}>
          <Inviter inviter={inviter} />
          <RegistrationForm
            hideHeadline={
              Boolean(inviter) && Object.values(inviter).some(value => value)
            }
            {...registrationFromProps}
          />
          <GdprImage />
        </IllustrationGrid>
        <UspSection />
      </CustomFrame>
    </>
  );
};

export default Invitation;
